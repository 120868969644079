import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var TrackingType = /*@__PURE__*/ (function (TrackingType) {
    TrackingType["APILds"] = "api_lds";
    TrackingType["TrackingLink"] = "smart_link_tracker";
    TrackingType["AITrackingLink"] = "smart_link_ai_tracker";
    TrackingType["PromoCode"] = "promo_code";
    TrackingType["LeaddistBroker"] = "leaddistbroker";
    /* th:start */
    TrackingType["Leverate"] = "leverate";
    return TrackingType;
})({});
export const TrackingTypeMap = new Map([
    [TrackingType.APILds, 'Global.Options.TrackingType.APILds'],
    [TrackingType.TrackingLink, 'Global.Options.TrackingType.TrackingLink'],
    [TrackingType.AITrackingLink, 'Global.Options.TrackingType.AITrackingLink'],
    [TrackingType.PromoCode, 'Global.Options.TrackingType.PromoCode'],
    [TrackingType.LeaddistBroker, 'Global.Options.TrackingType.LeaddistBrokerAPI'],
]);
/* th:start */
TrackingTypeMap.set(TrackingType.Leverate, 'Global.Options.TrackingType.LeadsAPI');
/* th:end */
export let TrackingTypeService = /*@__PURE__*/ (() => {
    class TrackingTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TrackingTypeMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    TrackingTypeService.ɵfac = function TrackingTypeService_Factory(t) { return new (t || TrackingTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    TrackingTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: TrackingTypeService, factory: TrackingTypeService.ɵfac });
    return TrackingTypeService;
})();

