import { WlCurrency } from '../../../../tools/environments'; // Included with Angular CLI.
import { version } from '../../../../package.json';
const sentryConfig = {
    dsn: 'https://d947e5eb231040b5b1ef1e5f466c4943@sentry.leaddist.team/9',
    debug: false,
    release: version,
    environment: '',
};
export const environment = {
    production: true,
    sentryClientTag: process.env.CLIENT_NAME + '-demo',
    apiUrl: 'https://tanos-api.t4u-ho.team/rest',
    appUrl: 'https://tanos-panel.t4u-ho.team/v3',
    jwtTokenName: 'id_token',
    deployUrl: '/v3/',
    role: [],
    gtmUID: 'GTM-W62H33G',
    gaUID: 'UA-126644119-2',
    projectVersion: version,
    sentryConfig,
    wlId: 'tanos-api.t4u-ho.team',
    wlAuthLogo: './assets/images/logo.svg',
    wlMainLargeLogo: './assets/images/logos/main-logo.svg',
    wlMainSmallLogo: './assets/images/logos/small-logo.svg',
    wlFavicon: '',
    wlPreloader: './assets/images/loading/loading-logo.png',
    wlCompanyName: 'Tracking Hub',
    wlHomePageLink: 'https://hugeoffers.com',
    wlTNCLink: 'https://hugeoffers.com/terms-and-conditions',
    wlSupportEmail: 'info@hugeoffers.com',
    wlSupportSkype: 'technical.support.HugeOffers',
    wlRegistrationSupportSkype: 'live:.cid.de122d97721bdde7',
    wlRegistrationSupportTelegram: 'KateHelpHO',
    wlCurrency: WlCurrency.USD,
};

