import { HttpErrorResponse } from '@angular/common/http';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import * as Sentry from '@sentry/browser';
import * as i0 from "@angular/core";
export let SentryErrorHandler = /*@__PURE__*/ (() => {
    class SentryErrorHandler {
        constructor(environment) {
            this.environment = environment;
            this._isEnableSentry = false;
        }
        init(customConfig) {
            try {
                Sentry.init(Object.assign(Object.assign({}, this.environment.sentryConfig), { debug: !this.environment.production, environment: customConfig.wlId, beforeSend(event, hint) {
                        const error = hint.originalException;
                        return !(error instanceof HttpErrorResponse) ? event : null;
                    } }));
                this._isEnableSentry = true;
            }
            catch (e) {
                console.error('error init sentry:', e);
            }
        }
        handleError(error) {
            if (!this._isEnableSentry) {
                return;
            }
            if (!~this.environment.apiUrl.indexOf('tanos-api.t4u-ho.team')) {
                if (!(error instanceof HttpErrorResponse)) {
                    if (error.hasOwnProperty('name')) {
                        error['name'] = 'LogicError';
                    }
                    Sentry.setTag('CLIENT_ID', this.environment.sentryClientTag);
                    Sentry.captureException(error.originalError || error);
                    throw error;
                }
                else if (![401, 422].includes(+error.error.response.status)) {
                    const err = new Error(error.error.response.message);
                    err['name'] = 'HttpErrorResponse';
                    Sentry.setExtras({ body: error.error.request.body });
                    Sentry.setTag('CLIENT_ID', this.environment.sentryClientTag);
                    Sentry.captureException(err);
                }
            }
        }
    }
    SentryErrorHandler.ɵfac = function SentryErrorHandler_Factory(t) { return new (t || SentryErrorHandler)(i0.ɵɵinject(AppEnvironment)); };
    SentryErrorHandler.ɵprov = i0.ɵɵdefineInjectable({ token: SentryErrorHandler, factory: SentryErrorHandler.ɵfac, providedIn: 'root' });
    return SentryErrorHandler;
})();

